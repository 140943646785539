.sidebar {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
}

.sidebar a {
  padding: 8px 0px 8px 0px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidebar a:hover {
  color: #f1f1f1;
  cursor: pointer;
}

.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

@media screen and (max-height: 450px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
}
@media screen and (max-width: 900px) {
  .sidebar {
    padding-top: 15px;
  }

  .sidebar a {
    font-size: 18px;
  }
  .opensidebar {
    width: auto;
  }
}

.opensidebar {
  cursor: pointer;
  color: white;
  border: none;
  background: none;
  font-size: 50px;
  position: absolute;
  left: 10px;
  top: 10px;
  text-align: left;
  padding-left: 0px; padding-right: 0px;
}