.contact-root {
    background: linear-gradient(138deg, rgba(217,11,245,1) 0%, rgba(5,96,187,1) 54%, rgba(0,212,255,1) 100%);
    height: 100vh;
    display: flex;
    justify-content: center;
    min-height: 100vh;
}
.contact-title {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    text-align: left;
}
.contact-title-primary {
    font-size: 50px;
    font-weight: bold;
    margin: 0px 0px 20px 0px;
}
.contact-title-secondary {
    font-size: 30px;
    margin: 0px;
}
.contact-form {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact-form-object {
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
}
.contact-form-object p {
    text-align: left;
    font-weight: bold;
}
.contact-form-object form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.contact-form-object form input {
    border-radius: 5px;
    background-color: rgb(222, 222, 222);
    border: none;
    padding: 10px;
}
.contact-form-text {
    margin: 0px;
}
.contact-form-content {
    width: 95%;
    height: 200px;
    border-radius: 5px;
    background-color: rgb(222, 222, 222);
    border: none;
    padding: 10px;
    resize: none;
}
.contact-form-object form button {
    width: 38%;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-image: linear-gradient(138deg, rgba(217,11,245,1) 0%, rgba(5,96,187,1) 54%, rgba(0,212,255,1) 100%);
    color: white;
    font-weight: bold;
    box-shadow: 5px 5px 5px rgb(168, 168, 168);
}
.contact-form-object form button:hover {
    cursor: pointer;
}

@media screen and (max-width: 900px) {
    .contact-root {
        flex-direction: column;
        gap: 5%;
        padding-top: 10%; padding-bottom: 10%;
    }
    .contact-title {
        width: 100%;
        align-items: center;
    }
    .contact-title-primary {
        font-size: 40px;
        font-weight: bold;
        margin: 0px 0px 20px 0px;
        text-align: center;
        width: 80%;
    }
    .contact-title-secondary {
        font-size: 25px;
        margin: 0px;
        text-align: center;
    }
    .contact-form {
        width: 100%;
    }
    .contact-form-object {
        width: 80%;
    }
    .contact-form-content {
        width: 95%;
    }
}