.about-root {
    background: linear-gradient(138deg, rgba(217,11,245,1) 0%, rgba(5,96,187,1) 54%, rgba(0,212,255,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.about-header {
    width: 80%;
    text-align: left;
}
.about-header-title {
    color: white;
    font-size: 40px;
    font-weight: 700;
    padding-top: 25px;
    margin-bottom: 0px;
}
.about-data {
    width: 80%;
    display: flex;
    gap: 50px;
}
.about-text {
    color: white;
    text-align: left;
    width: 50%;
}
.about-image {
    width: 50%;
    align-items: center;
}
.about-image img {
    max-height: 500px;
    border-radius: 20px;
}

@media screen and (max-width: 900px) {
    .about-data {
        flex-direction: column;
    }
    .about-text {
        width: 80%;
    }
    .about-image {
        width: 100%;
    }
    .about-image img {
        max-width: 100%;
    }
}