.experience-root {
    background: linear-gradient(138deg, rgba(217,11,245,1) 0%, rgba(5,96,187,1) 54%, rgba(0,212,255,1) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
}
.coming-soon {
    width: 100vw; height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.coming-soon p {
    color: white;
    font-weight: bold;
    font-size: 25;
}