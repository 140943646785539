.home-root {
    background: linear-gradient(138deg, rgba(217,11,245,1) 0%, rgba(5,96,187,1) 54%, rgba(0,212,255,1) 100%);
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.home-title {
    vertical-align: middle;
    color: white;
}
@keyframes loadName {
    0% { opacity: 0%; transform: translateY(30px); }
    100% { opacity: 100%; }
}
.home-name {
    font-size: 100px;
    margin: 0px;
    animation: loadName 2s;
}
@keyframes loadProfession {
    0% { opacity: 0%; }
    50% { opacity: 0%; transform: translateY(30px); }
    100% { opacity: 100%; }
}
.home-profession {
    animation: loadProfession 3s;
}
.home-socials {
    margin-top: 100px;
}
.home-socials-img {
    height: 50px;
    padding: 10px;
}