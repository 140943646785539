.portfolio-object-root {
    width: 40%;
    background-color: white;
    padding: 10px;
    text-align: left;
    border-radius: 10px;
}

.portfolio-object-header {
    display: flex;
    justify-content: space-between;
}

.portfolio-object-title {
    font-weight: bold;
}

.portfolio-object-data {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.portfolio-object-info {}

.portfolio-object-description {}

.portfolio-object-stack {}

.portfolio-object-repo {
    /* text-decoration: none; */
    color: cornflowerblue;
    font-weight: 700;
}

.portfolio-object-images {}

.portfolio-object-image {
    width: 100%;
    max-height: 700px;
}

.portfolio-object-image-swap {
    cursor: pointer;
}


@media screen and (max-width: 900px) {
    .portfolio-object-root {
        width: 80%;
    }
}