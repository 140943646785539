.portfolio-root {
    background: linear-gradient(138deg, rgba(217,11,245,1) 0%, rgba(5,96,187,1) 54%, rgba(0,212,255,1) 100%);
    display: flex;
    flex-direction: column;
    flex: 1;
}
.portfolio-title {
    width: 100%;
    vertical-align: middle;
    color: white;
    padding-top: 20px; padding-bottom: 20px;
}
.portfolio-name {
    font-size: 40px;
    font-weight: 700;
    margin: 0px;
    padding-top: 25px;
}
.portfolio-objects {
    width: 100%;
    display: flex;
    flex: 1;
    gap: 20px;
    justify-content: center;
    padding-bottom: 50px;
    flex-wrap: wrap;
}
